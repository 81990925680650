import React, { useState } from 'react';
import Questions from '../questions/questions.js';
import Result from '../result/Result.js';

export default function Main({ nextQuestion, question, upHolder, upScammerChad, upWinnerLooser, upWhale, upRisquy, upIsDev, upDegen, upTimestamp, isFinished, setIsFinished, checkWalletAddress, db, createTxt, setTitle, name, upName }) {
  const [isStarted, setIsStarted] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [data, setData] = useState(null);
  const [userName, setUserName] = useState(name || '');
  const [showInput, setShowInput] = useState(true); // Initialize to true to show input directly
  const [error, setError] = useState('');

  const validateName = (name) => {
    const regex = /^[a-zA-Z0-9-]{2,12}$/;
    return regex.test(name);
  };

  const checkName = async () => {
    try {
      if (!userName) {
        setError('Name is required');
        setShowInput(true);
      } else if (!validateName(userName)) {
        setError('Name must be 2-12 characters long and can only contain letters, numbers, and hyphens');
      } else {
        setError('');
        setTitle();
        upName(userName);
        const nameExists = await checkWalletAddress(userName, db); // Use userName instead of name
        if (nameExists) {
          setData(nameExists);
          setShowResult(true);
          setError('Name existe déjà'); // Set error message if name exists
          if (nameExists && typeof createTxt === 'function') {
            createTxt(nameExists, userName);
          }
        } else {
          setIsStarted(true);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An unexpected error occurred. Please try again later.');
    }
  };

  console.log('Rendering Main component');
  console.log('isStarted:', isStarted);

  return (
    <div className="h-5/6 flex flex-col items-center justify-center w-full">
      {showResult ? (
        <>
          <Result data={data} createTxt={createTxt} />
        </>
      ) : (
        <>
          <Questions 
            isStarted={isStarted} 
            nextQuestion={nextQuestion} 
            question={question} 
            upHolder={upHolder} 
            upScammerChad={upScammerChad} 
            upWinnerLooser={upWinnerLooser} 
            upWhale={upWhale} 
            upRisquy={upRisquy} 
            upIsDev={upIsDev} 
            upDegen={upDegen} 
            upTimestamp={upTimestamp} 
            isFinished={isFinished} 
            setIsFinished={setIsFinished} 
          />
          <div className="flex flex-col items-center">
            {!isStarted && (
              <>
                {showInput && (
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="mb-4 p-2 border rounded"
                  />
                )}
                {error && <p className="text-red-500">{error}</p>}
                <button
                  className="bg-green-700 text-white font-bold py-2 px-4 rounded hover:bg-green-800"
                  onClick={() => {
                    console.log('Button clicked');
                    checkName();
                  }}
                >
                  Start
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}