import React, { useEffect } from 'react';


export default function About() {


  return (
    <>
    <div className="flex flex-col items-center justify-center z-10 w-1/2 h-2/3 bg-slate-800 p-4 md:bg-transparent">
      <h1 className='text-white font-light   md:text-lg'>Pepersonalities is a fun questionnaire that analyzes traders' personalities based on their trading behavior. Each name is entitled to only one test. With no scientific basis, this test offers a unique and entertaining insight into trading styles.
      “This questionnaire awakens the hidden Pepe in all of us."</h1>
    </div>

    <div className="flex flex-col items-center justify-center z-10 w-2/3 h-2/3  bg-slate-800  md:bg-transparent p-4">
      <h1 className='text-white  md:text-lg'><br/>If you have any suggestions for improvements, ideas, questions or partnership proposals, please don't hesitate to contact the Dev here : <a href="https://x.com/Spaa_des" target='_blank' className='text-blue-700 hover:text-blue-900'>@Spaa_des</a></h1>
    </div>
    </>
  );
}