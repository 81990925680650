import React from 'react';
import listQuestions from '../../questions.json';
import styles from './styles.module.css'; // Assurez-vous de créer ce fichier CSS
export default function Sentence({ id, upCategory }) {
  



  return (
    <div className={`flex flex-col items-center justify-center text-2xl text-white ${styles.txt}`}>
      <h1>{(listQuestions[id].id+1)}/{listQuestions.length} :</h1>
      <h1>{listQuestions[id].question}</h1>
    </div>
  );
}