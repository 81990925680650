import React from 'react';


export default function Tokenomics() {
  return (
    <div className='flex flex-row justify-center items-center'>
  <div className="flex flex-col items-center justify-center z-10 h-2/3 bg-slate-800 border p-4">
    <h1 className='text-white  md:text-lg'>CA: SOON</h1>
    <h1 className='text-white  md:text-lg'>TICKER: SOON</h1>
    <h1 className='text-white  md:text-lg '>SUPPLY: 1.000.000.000</h1>
    <h1 className='text-white  md:text-lg'>MINT: REVOKED</h1>

    <div className='flex flex-row justify-center items-center'>
      <h1 className='text-white bg-blue-600 p-4 hover:bg-blue-900 m-2 w-24 flex justify-center items-center'>
        <a target='_blank' rel='noopener noreferrer' href='https://t.me/pepersonalities' className='text-white text-center'>TELEGRAM</a>
      </h1>
      <h1 className='text-white bg-blue-600 p-4 hover:bg-blue-900 m-2 w-24 flex justify-center items-center'>
        <a target='_blank' rel='noopener noreferrer' href='https://x.com/Pepersonalities' className='text-white text-center'>TWITTER</a>
      </h1>
    </div>
  </div>
</div>
  );
}