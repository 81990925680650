import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Stats = ({ db }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'degen'));
      const items = [];

      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });

      setData(items);
    };

    fetchData();
  }, [db]);

  // Calculer les pourcentages et les nombres de wallets positifs et négatifs pour chaque catégorie
  const calculateStats = (data) => {
    const categories = ['holder', 'scammerChad', 'winnerLooser', 'whale', 'risquy', 'isDev', 'degen'];
    const stats = {};
    const total = data.length;

    categories.forEach(category => {
      const positive = data.filter(item => item[category] > 0).length;
      const negative = total - positive;
      stats[category] = {
        positivePercentage: (positive / total) * 100,
        negativePercentage: (negative / total) * 100,
      };
    });

    return stats;
  };

  const stats = calculateStats(data);
  const totalAddresses = data.length;

  // Préparer les données pour le graphique
  
  return (
    <div className='z-10'>
      <div className='bg-slate-800 z-10 p-4 w-96 h-auto font-mono  border p-4'>
      <p className='text-white font-mono'>Total participating addresses: <span className='text-green-700 font-mono'>{totalAddresses}</span><br /><br /><br /></p>
        <p className='text-white font-mono'>- Jeeters' percentage: <span className='text-green-700 font-mono'>{stats.holder.positivePercentage.toFixed(2)}%</span></p>
        <p className='text-white font-mono'>- Percentage of people who have used dishonest methods: <span className='text-green-700 font-mono'>{stats.scammerChad.positivePercentage.toFixed(2)}%</span></p>
        <p className='text-white font-mono'>- Percentage of portfolio in loss since memecoin investment: <span className='text-green-700 font-mono'>{Math.abs(stats.winnerLooser.negativePercentage.toFixed(2))}%</span></p>
        <p className='text-white font-mono'>- Percentage of Wallets trading with risk: <span className='text-green-700 font-mono'>{stats.risquy.positivePercentage.toFixed(2)}%</span></p>
        <p className='text-white font-mono'>- Percentage of wallets investing large sums: <span className='text-green-700 font-mono'>{stats.whale.positivePercentage.toFixed(2)}%</span></p>
        <p className='text-white font-mono'>- Percentage of wallets that invested in $PEPER: <span className='text-green-700 font-mono'>{stats.degen.positivePercentage.toFixed(2)}%</span></p>
        
      </div>
    </div>
//    
//       <tr className="bg-purple-500">
//         <th className="px-4 py-2"></th>
//         <th className="px-4 py-2">Yes</th>
//         <th className="px-4 py-2">No</th>
//       </tr>
//     </thead>
//     <tbody>
//
//     </tbody>
//   </table>

//       </tr>
//     </tbody>
//   </table>

//   <h3 className="text-lg font-semibold mb-2">PEPER Investors</h3>
//   <table className="table-auto w-full mb-4">
//     <thead>
//       <tr className="bg-purple-500">
//         <th className="px-4 py-2"></th>
//         <th className="px-4 py-2">Yes</th>
//         <th className="px-4 py-2">No</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr>
//         <th className="border px-4 py-2">Wallets investing in $PEPER</th>
//         <td className="border px-4 py-2">{stats.isDev.positivePercentage.toFixed(2)}%</td>
//         <td className="border px-4 py-2">{Math.abs(stats.isDev.negativePercentage.toFixed(2))}%</td>
//       </tr>
//     </tbody>
//   </table>

// </div>

// </div>

      /*
      
    
      <tr>
        <td className='border px-4 py-2' colSpan='3'></td>
      </tr>
      <tr>
        <th className='border px-4 py-2'>Is Dev</th>
        <td className='border px-4 py-2'>{stats.isDev.positivePercentage.toFixed(2)}%</td>
        <td className='border px-4 py-2'>{Math.abs(stats.isDev.negativePercentage.toFixed(2))}%</td>
      </tr>
      <tr>
        <td className='border px-4 py-2' colSpan='3'></td>
      </tr>
      <tr>
        <th className='border px-4 py-2'>Degen</th>
        <td className='border px-4 py-2'>{stats.degen.positivePercentage.toFixed(2)}%</td>
        <td className='border px-4 py-2'>{Math.abs(stats.degen.negativePercentage.toFixed(2))}%</td>
      </tr> */

// </div>
//     </div>
  );
};

export default Stats;