import "./App.css";
import Main from "./components/main/main.js";
import Result from "./components/result/Result.js";
import React, { useState, useEffect } from "react";
import Stats from "./components/stats/Stats.js";
import { initializeApp } from "firebase/app";
import Tokenomics from "./components/tokenomics/Tokenomics.js";
import About from "./components/about/About.js";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import "firebase/auth";

function App() {
  const [isFinished, setIsFinished] = useState(false);

  const [idQuestion, setIdQuestion] = useState(0);
  /* SC */ const [scammerChad, isScammerChad] = useState(0); // - = scammer, + = chad
  /* H */ const [holder, setHolder] = useState(0); // - = jeeter, + = holder
  /* WL */ const [winnerLooser, setWinnerLooser] = useState(0); // - = looser, + = winner
  /* W */ const [whale, setWhale] = useState(0); // - = fish, + = whale
  /* R */ const [risquy, setRisquy] = useState(0); // - = risquy, + = safe
  /* DEV */ const [isDev, setIsDev] = useState(0); // - = no, + = yes
  /* DG */ const [degen, setDegen] = useState(0);
  /* T */ const [timestamp, setTimestamp] = useState(0);
  const [currentView, setCurrentView] = useState("home");
  const [title, setTitle] = useState(true);
  const [name, setName] = useState(null);
  const [data, setData] = useState(null);

  const [clientResult, setClientResult] = useState(null);
  //--------------------------------------------------

  const firebaseConfig = {
    apiKey: "AIzaSyBvwYSZ67dFoCieSuYVI_19TMvOw6DNj8w",
    authDomain: "degen-f7aaf.firebaseapp.com",
    projectId: "degen-f7aaf",
    storageBucket: "degen-f7aaf.appspot.com",
    messagingSenderId: "783525413443",
    appId: "1:783525413443:web:ea02dc43344e83e30c4af6",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  //--------------------------------------------------
  function upFinished() {
    setIsFinished(true);
  }
  function nextQuestion() {
    setIdQuestion(idQuestion + 1);
  }

  function upScammerChad(nbr) {
    isScammerChad(scammerChad + nbr);
  }
  function upHolder(nbr) {
    setHolder(holder + nbr);
  }
  function upWinnerLooser(nbr) {
    setWinnerLooser(winnerLooser + nbr);
  }
  function upWhale(nbr) {
    setWhale(whale + nbr);
  }
  function upRisquy(nbr) {
    setRisquy(risquy + nbr);
  }
  function upIsDev(nbr) {
    setIsDev(isDev + nbr);
  }
  function upDegen(nbr) {
    setDegen(nbr);
  }
  function upTimestamp(nbr) {
    setTimestamp(nbr);
  }
  function upTitle() {
    setTitle(false);
  }
  function upTitle2() {
    setTitle(true);
  }
  function upName(name) {
    setName(name);
  }

  function upData(data) {
    setData(data);
  }



  const createTxt = (data) => {
    const thief = data.scammerChad;
    const createdToken = data.isDev;
    const degen = data.degen;
    const winner = data.winnerLooser;
    const largeInvestment = data.whale;
    const riskTrade = data.risquy;
    const tradeOften = data.timestamp;
    const holder = data.holder;

    let conclusion = `${data.address},<br><br>`;

    // Evaluate if the user is honest
    if (thief <= -1) {
        conclusion += "You demonstrate impeccable honesty, never succumbing to questionable practices.\n";
    } else if (thief > -1 && thief <= 2) {
        conclusion += "Generally honest, though sometimes inclined to bend the truth if necessary.\n";
    } else if (thief > 2) {
        conclusion += "Honesty doesn’t hold you back; you’re willing to do whatever it takes to succeed.\n";
    }

    // Evaluate if the user is a holder
    if (holder <= -2) {
        conclusion += "Not the type to hold onto your cryptos for long; you prefer to sell quickly.\n";
    } else if (holder > -2 && holder <= 2) {
        conclusion += "You strike a balance between patience and reactivity, capable of holding, but also ready to act.\n";
    } else if (holder > 2) {
        conclusion += "Believing in the long term, you keep your cryptos without worrying too much about fluctuations.\n";
    }

    // Evaluate if the user is winning or losing
    if (winner <= -2) {
        conclusion += "Results aren’t always in your favor, but perseverance remains your strength.\n";
    } else if (winner > -2 && winner <= 2) {
        conclusion += "Gains and losses balance out, with overall results being fairly average.\n";
    } else if (winner > 2) {
        conclusion += "Often winning, your trading choices are paying off well.\n";
    }

    // Evaluate if the user makes large investments
    if (largeInvestment <= -1) {
        conclusion += "Currently, your investments are modest.\n";
    } else if (largeInvestment > -1 && largeInvestment <= 1) {
        conclusion += "You make reasonable investments, neither too small nor excessively large.\n";
    } else if (largeInvestment > 1) {
        conclusion += "You aren’t afraid to invest large sums, showing strong commitment.\n";
    }

    // Evaluate if the user takes risks
    if (riskTrade <= -1) {
        conclusion += "You prefer to avoid risks and prioritize safety.\n";
    } else if (riskTrade > -1 && riskTrade <= 1) {
        conclusion += "You don’t mind taking risks but also know how to be cautious.\n";
    } else if (riskTrade > 1) {
        conclusion += "Bold in your choices, you’re willing to take high risks to maximize gains.\n";
    }

    // Evaluate if the user has created a token
    if (createdToken) {
        conclusion += "You have the initiative; you’ve even created your own token.\n";
    } else {
        conclusion += "Creating a token isn’t on your list yet, but it could be in the future.\n";
    }

    // Evaluate if the user trades often
    if (tradeOften <= -1) {
        conclusion += "Not very active in the markets, you prefer to trade with moderation.\n";
    } else if (tradeOften > -1 && tradeOften <= 1) {
        conclusion += "Balanced activity in the markets, without excess but with a watchful eye.\n";
    } else if (tradeOften > 1) {
        conclusion += "Always on the move, you’re constantly seeking new opportunities.\n";
    }

    // Bonus evaluation for degen
    if (degen) {
        conclusion += "\nYou’ve embraced the ultimate degen lifestyle by investing in $PEPER! Your adventurous spirit knows no bounds, and you’re truly a trailblazer in the world of memecoins!\n";
    } else {
        conclusion += "\nYou haven’t yet embarked on the epic journey of investing in $PEPER. But fear not! The adventure awaits, and perhaps one day you’ll join the ranks of the bold and daring pioneers!\n";
    }

    // Final conclusion
    conclusion += "<br><br>In summary, " +
        (thief <= -1 ? "you demonstrate impeccable honesty" : thief > 2 ? "honesty doesn’t hold you back" : "generally honest, but not rigid") +
        ", with " +
        (holder <= -2 ? "a strong tendency to act quickly" : holder > 2 ? "patience to endure" : "a good balance between patience and reactivity") +
        ". In terms of performance, " +
        (winner <= -3 ? "results aren’t always favorable" : winner > 3 ? "you’re often winning" : "results are fairly balanced") +
        ". And regarding investments, " +
        (largeInvestment <= -1 ? "your investments remain modest" : largeInvestment > 1 ? "you aren’t afraid to invest large sums" : "you make reasonable investments") +
        ". Continue trading at your own pace and enjoy this unique world of memecoins.";

    return conclusion;
};


const checkName = async (name, db) => {
  if (name) {
    const upperCaseName = name.toUpperCase(); // Convertir le nom en majuscules
    const docRef = doc(db, "degen", upperCaseName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data(); // Retourne les données du document
    } else {
      return null; // Retourne null si le document n'existe pas
    }
  }
  return null; // Retourne null si l'adresse du portefeuille est vide
};

  useEffect(() => {
    if (isFinished) {
      const result = {
        address: name.toUpperCase(),
        scammerChad: scammerChad,
        holder: holder,
        winnerLooser: winnerLooser,
        whale: whale,
        risquy: risquy,
        isDev: isDev,
        degen: degen,
        timestamp: timestamp,
      };

      const sendResult = doc(collection(db, "degen"), name.toUpperCase());
      setDoc(sendResult, result);
      setClientResult(result);
    }
  }, [
    isFinished,
    name,
    scammerChad,
    holder,
    winnerLooser,
    whale,
    risquy,
    isDev,
    degen,
    timestamp,
    db,
  ]);


  const [maskStyle, setMaskStyle] = useState({
    maskImage: "radial-gradient(circle 60px at center, transparent, #00008B)",
    webkitMaskImage:
      "radial-gradient(circle 60px at center, transparent, #00008B)",
  });

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    setMaskStyle({
      maskImage: `radial-gradient(circle 200px at ${x}px ${y}px, transparent 0%, #00008B 100%)`,
      webkitMaskImage: `radial-gradient(circle 200px at ${x}px ${y}px, transparent 0%, #00008B 100%)`,
    });
  };



  useEffect(() => {
    const fetchData = async () => {
      const result = await checkName(name, db);
      setData(result);
    };
    fetchData();
  }, [name, db]);



  return (
    <div onMouseMove={handleMouseMove}>
      <div className="background-layer"></div>
      <div className="masked-background" style={maskStyle}></div>
      <div className="content-layer">
        <nav className="w-full bg-slate-800 p-4 flex justify-between items-center fixed top-0 left-0 z-50">
          <div className="text-white font-bold text-xl hidden md:block">
            <img
              src="/assets/img/pepegif.gif"
              width={80}
              height={80}
              alt="Description de l'image"
            />
          </div>
          <ul className="flex space-x-4">
            <li>
              <button
                className= " text-white hover:text-gray-300 cursor-pointer bg-transparent border-none p-0 m-0 md:text-lg"
                onClick={() => {
                  setCurrentView("home");
                  upTitle2();
                   // Réinitialiser isFinished
                }}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className="text-white md:text-lg hover:text-gray-300 cursor-pointer bg-transparent border-none p-0 m-0"
                onClick={() => {
                  setCurrentView("about");
                  upTitle2();
     
                }}
              >
                About
              </button>
            </li>
            <li>
              <button
                className="text-white md:text-lg hover:text-gray-300 cursor-pointer bg-transparent border-none p-0 m-0"
                onClick={() => {
                  setCurrentView("stats");
                  upTitle2();
          
                }}
              >
                Stats
              </button>
            </li>
            <li>
              <button
                className="text-white md:text-lg hover:text-gray-300 cursor-pointer bg-transparent border-none p-0 m-0"
                onClick={() => {
                  setCurrentView("tokenomics");
                  upTitle2();

                }}
              >
                Tokenomics
              </button>
            </li>
          </ul>
        </nav>
        <div className="min-h-screen flex flex-col items-center justify-center w-full z-50 pt-20 ">
          {currentView === "home" && (
            <>
              {!isFinished && title && (
                <>
                  <h1 className="text-4xl md:text-7xl font-bold mt-4 z-10 text-white text-outline-red tiitle">
                    <span className="text-green-700 text-outline-white">
                      Pepe
                    </span>
                    rsonalities
                  </h1>
  
                  <h2 className="text-center mb-4 z-10 text-white text-2xl md:text-4xl mt-10 ">
                    “It's so{" "}
                    <span className="text-green-700   text-outline-white">
                      amazing
                    </span>{" "}
                    to finally be a degen{" "}
                    <span className="text-green-700   text-outline-white">
                      understood
                    </span>
                    .”
                  </h2>
                </>
              )}
  
              <div className="flex flex-col items-center justify-center p-6 rounded-lg z-10 w-11/12 h-96 bg">
                {isFinished ? (
                  <div className="flex flex-col items-center justify-center"  >
                    <h1 className="text-white text-3xl font-bold underline z-10 text-center ">Quiz Finished</h1>
                    <p className="text-green-700 z-10 text-center">Thank you for participating!</p>
                    {clientResult ? (
                      <Result data={clientResult} createTxt={() => createTxt(clientResult, name)} />
                    ) : (
                      <p className="text-white z-10">No data available</p>
                    )}
                  </div>
                ) : (
                  <Main
                    nextQuestion={nextQuestion}
                    question={idQuestion}
                    upHolder={upHolder}
                    upScammerChad={upScammerChad}
                    upWinnerLooser={upWinnerLooser}
                    upWhale={upWhale}
                    upRisquy={upRisquy}
                    upIsDev={upIsDev}
                    upDegen={upDegen}
                    upTimestamp={upTimestamp}
                    isFinished={isFinished}
                    setIsFinished={upFinished}
                    checkWalletAddress={checkName}
                    db={db}
                    createTxt={createTxt}
                    setTitle={upTitle}
                    name={name}
                    upName={upName}
                    data={data}
                    upData={upData}
                  />
                )}
              </div>
            </>
          )}
          {currentView === "tokenomics" && <Tokenomics />}{" "}
          {/* Affichez Tokenomics si currentView est 'tokenomics' */}
          {currentView === "stats" && <Stats db={db} />}{" "}
          {/* Affichez Tokenomics si currentView est 'tokenomics' */}
          {currentView === "about" && <About />}{" "}
          {/* Affichez Tokenomics si currentView est 'tokenomics' */}
        </div>
      </div>
      <img
        className="z-10 fixed bottom-0 right-0 rotate-x-180  hidden md:block"
        src="/assets/img/pepegif.gif"
        width={380}
        height={380}
        alt="Description de l'image"
      />
    </div>
  );
}

export default App;
