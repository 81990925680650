import React, { useEffect, useRef } from 'react';

export default function Result({data, createTxt }) {
    const canvasRef = useRef(null);

    useEffect(() => {
        const loadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        };

        const drawImages = async () => {
            if (!data) {
                console.error('Data is undefined');
                return;
            }

            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const images = [];



            if( data.winnerLooser > 5){
                const imageSources = [
                    data.timestamp <= 2 ? "/assets/img/time/3.png" : data.timestamp <= 0 ? "/assets/img/time/2.png" : "/assets/img/time/1.png",
                    "/assets/img/pepe.png",
                    data.scammerChad <= -1 ? "/assets/img/scm/3.png" : data.scammerChad <= 2 ? "/assets/img/scm/2.png" : "/assets/img/scm/1.png",
                    data.winnerLooser <= -2 ? "/assets/img/winn/1.png" : data.winnerLooser <= 2 ? "/assets/img/winn/2.png" : "/assets/img/winn/4.png",
                    data.whale <= -1 ? "/assets/img/whale/1.png" : data.whale <= 1 ? "/assets/img/whale/2.png" : "/assets/img/whale/3.png",
                    (data.risquy === 0 && data.holder <= -2) ? null : (data.risquy <= -1 ? "/assets/img/risquy/1.png" : data.risquy <= 1 ? "/assets/img/risquy/2.png" : "/assets/img/risquy/3.png"),
                    data.holder <= -2 ? "/assets/img/holder/1.png" : data.holder <= 2 ? "/assets/img/holder/2.png" : "/assets/img/holder/3.png",
                    data.isDev ? "/assets/img/isDev/1.png" : null,
                    data.degen ? "/assets/img/isDegen/1.png" : null,
                ].filter(Boolean);
                for (const src of imageSources) {
                    const img = await loadImage(src);
                    images.push(img);
                }
    
                images.forEach((img) => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                });
            }
            else{
                const imageSources = [
                    data.timestamp <= -2 ? "/assets/img/time/3.png" : data.timestamp <= 2 ? "/assets/img/time/2.png" : "/assets/img/time/1.png",
                    "/assets/img/pepe.png",
                    data.winnerLooser <= -2 ? "/assets/img/winn/1.png" : data.winnerLooser <= 2 ? "/assets/img/winn/2.png" : "/assets/img/winn/4.png",
                    data.scammerChad <= -1 ? "/assets/img/scm/3.png" : data.scammerChad <= 2 ? "/assets/img/scm/2.png" : "/assets/img/scm/1.png",
                    data.whale <= -1 ? "/assets/img/whale/1.png" : data.whale <= 1 ? "/assets/img/whale/2.png" : "/assets/img/whale/3.png",
                    (data.risquy === 0 && data.holder <= -2) ? null : (data.risquy <= -1 ? "/assets/img/risquy/1.png" : data.risquy <= 1 ? "/assets/img/risquy/2.png" : "/assets/img/risquy/3.png"),
                    data.holder <= -2 ? "/assets/img/holder/1.png" : data.holder <= 2 ? "/assets/img/holder/2.png" : "/assets/img/holder/3.png",
                    data.isDev ? "/assets/img/isDev/1.png" : null,
                    data.degen ? "/assets/img/isDegen/1.png" : null,
                ].filter(Boolean);
                for (const src of imageSources) {
                    const img = await loadImage(src);
                    images.push(img);
                }
    
                images.forEach((img) => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                });
            }
            

           
        };

        drawImages();
    }, [data]);



    const downloadCanvasAsImage = () => {
        const canvas = canvasRef.current;
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'pepersonalities.png';
        link.click();
    };

    return (
        <div className="w-full md:w-3/4 flex flex-col md:flex-row items-center justify-center z-40">
        <div className='flex flex-col items-center justify-center m-5'>
            <canvas ref={canvasRef} width={300} height={300} className="relative w-56 h-56"></canvas>
            <button onClick={downloadCanvasAsImage} className="bg-green-600 hover:bg-green-800 text-white px-4 py-2 rounded mt-4">
                Download Image
            </button>
        </div>
        <div className='flex flex-col md:flex-row items-center bg-slate-800 md:bg-transparent p-4 h-auto text-xs md:text-base justify-center text-white'>
            <div dangerouslySetInnerHTML={{ __html: createTxt(data) }} />
        </div>
    </div>
    );
}