

export default function Btns({ listQuestions,upPercentage, nextQuestion, question, upHolder,upScammerChad, upWinnerLooser, upWhale, upRisquy, upIsDev, upDegen, upTimestamp }) {

    function up(btn){

        if(listQuestions[question].category === "H"){
            upHolder(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "WL"){
            upWinnerLooser(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "W"){
            upWhale(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "R"){
            upRisquy(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "DEV"){
            upIsDev(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "DG"){
            upDegen(listQuestions[question].pts[btn]);
        }
        else if(listQuestions[question].category === "T"){
            upTimestamp(listQuestions[question].pts[btn]);
        }
        else{
            upScammerChad(listQuestions[question].pts[btn]);
        }
       
        
    }


    return (
      <div className="flex flex-row items-center justify-around mt-6">
        <button className="bg-green-500 hover:bg-green-700 text-white h-24 w-32 font-bold py-2 px-4 rounded ml-4 mr-4" onClick={() => {
        nextQuestion();

        upPercentage();
        up(0);
    }}>Yes</button>
        <button className="bg-slate-500 hover:bg-slate-700 text-white h-24 w-32 font-bold py-2 px-4 rounded ml-4 mr-4"onClick={() => {
        nextQuestion();

        upPercentage();
        up(1);
    }}>Skip</button>
        <button className="bg-red-500 hover:bg-red-700 text-white font-bold w-32 h-24 py-2 px-4 rounded  ml-4 mr-4" onClick={() => {
        nextQuestion();
        console.log('Button clicked');
        upPercentage();
        up(2);
    }}>No</button>
    
        </div>
    );
}