import Sentence from '../sentence/Sentence';
import Btns from '../Btns/Btns';
import Scroll_bar from '../scroll_bar/Scroll_bar';
import { useState, useEffect } from 'react';
import listQuestions from '../../questions.json';


export default function Questions({ isStarted, nextQuestion, question, upHolder,upScammerChad, upWinnerLooser, upWhale, upRisquy, upIsDev, upDegen,upTimestamp,  setIsFinished }) {


const nbQuestions = listQuestions.length;
console.log(nbQuestions);

const [percentage, setPercentage] = useState(0);


function upPercentage(){
    setPercentage(percentage + (100/nbQuestions));
  
}

useEffect(() => {
  if (question === nbQuestions - 1) {

    setIsFinished(true);

 
  }
}, [question, nbQuestions, setIsFinished]);


    if (isStarted) {
      return (
        <>
        
        <Sentence id={question} />
        <Btns listQuestions={listQuestions} upPercentage = {upPercentage} nextQuestion={nextQuestion} question={question} upHolder={upHolder} upScammerChad={upScammerChad} upWinnerLooser={upWinnerLooser} upWhale={upWhale} upRisquy={upRisquy} upIsDev={upIsDev} upDegen={upDegen} upTimestamp={upTimestamp} />
        <Scroll_bar percentage={percentage}/>
        </>      );
    } else {
      return (
        <></>
      );
    }
  }